<template>
  <div>
    <HomeAppBar />
    <router-view />
    <BottomNavigationBar />
  </div>
</template>
<script>
import HomeAppBar from "@/components/HomeAppBar.vue";
import BottomNavigationBar from "@/components/BottomNavigationBar.vue";
export default {
  components: {
    HomeAppBar,
    BottomNavigationBar,
  },
};
</script>
